import React, { lazy, Suspense } from 'react'
import * as Sentry from '@sentry/browser'
import ReactDOM from 'react-dom'

import 'antd/dist/antd.css'
import { ENV, PUBLIC_SENTRY_DSN, RELEASE_VERSION, ENABLE_SENTRY_IGNORE_URLS } from 'config'

//#plopjsRequired: pagesDeclarations
const CollaboratorDetail = lazy(() =>
  import('app/pages/collaborator-detail' /* webpackChunkName: 'collaborator-detail' */)
)
const AnalyticsDashboard = lazy(
  () => import('app/pages/analytics-dashboard') /* webpackChunkName: 'analytics-dashboard' */
)
const Collaborators = lazy(() =>
  import('app/pages/collaborators' /* webpackChunkName: 'collaborators' */)
)
const ContactProfile = lazy(() =>
  import('app/pages/contact-profile' /* webpackChunkName: 'contact-profile' */)
)
const Contacts = lazy(() => import('app/pages/contacts' /* webpackChunkName: 'contacts' */))
const CopyrightDetail = lazy(() =>
  import('app/pages/copyright-detail' /* webpackChunkName: 'copyright-detail' */)
)
const Docket = lazy(() =>
  import('app/pages/docket' /* webpackChunkName: 'docket', webpackPreload: true */)
)
const FirmDocket = lazy(() =>
  import('app/pages/firm-docket' /* webpackChunkName: 'firm-docket', webpackPreload: true */)
)
const FirmInfo = lazy(() => import('app/pages/firm-info' /* webpackChunkName: 'firm-info' */))
const FirmTagManager = lazy(() =>
  import('app/pages/firm-tag-manager' /* webpackChunkName: 'firm-tag-manager' */)
)
const FirmRolesManager = lazy(() =>
  import('app/pages/firm-roles-manager' /* webpackChunkName: 'firm-roles-manager' */)
)
const AccountRecovery = lazy(() =>
  import('app/pages/account-recovery' /* webpackChunkName: 'account-recovery' */)
)
const IntakePatentDetail = lazy(() =>
  import('app/pages/intake-patent-detail' /* webpackChunkName: 'intake-patent-detail' */)
)
const IntakePatentFill = lazy(() =>
  import('app/pages/intake-patent-fill' /* webpackChunkName: 'intake-patent-fill' */)
)
const Intakes = lazy(() => import('app/pages/intakes' /* webpackChunkName: 'intakes' */))
const IntakeSignUp = lazy(() =>
  import('app/pages/intake-sign-up' /* webpackChunkName: 'intake-sign-up' */)
)
const IntakeTrademarkDetail = lazy(() =>
  import('app/pages/intake-trademark-detail' /* webpackChunkName: 'intake-trademark-detail' */)
)
const IntakeTrademarkFill = lazy(() =>
  import('app/pages/intake-trademark-fill' /* webpackChunkName: 'intake-trademark-fill' */)
)
const LicensureDetail = lazy(() =>
  import('app/pages/licensure-detail' /* webpackChunkName: 'licensure-detail' */)
)
const Login = lazy(() => import('app/pages/login' /* webpackChunkName: 'login' */))
const OppositionDetail = lazy(() =>
  import('app/pages/opposition-detail' /* webpackChunkName: 'opposition-detail' */)
)
const PasswordReset = lazy(() => import('app/pages/pw-reset' /* webpackChunkName: 'pw-reset' */))
const PatentDetail = lazy(() =>
  import('app/pages/patent-detail' /* webpackChunkName: 'patent-detail' */)
)
const Profile = lazy(() => import('app/pages/profile' /* webpackChunkName: 'profile' */))
const ReportManager = lazy(() =>
  import('app/pages/report-manager' /* webpackChunkName: 'report-manager' */)
)
const SignUp = lazy(() => import('app/pages/sign-up' /* webpackChunkName: 'sign-up' */))
const Static = lazy(() => import('app/pages/static' /* webpackChunkName: 'static' */))
const TrademarkDetail = lazy(() =>
  import('app/pages/tm-detail' /* webpackChunkName: 'tm-detail' */)
)
const CanadianTrademarkDetail = lazy(() =>
  import(
    'app/pages/canadian-trademark-detail'
    /* webpackChunkName: 'canadian-trademark-detail' */
  )
)
const Autotracking = lazy(() =>
  import('app/pages/autotracking' /* webpackChunkName: 'autotracking' */)
)
const CustomMatterDetail = lazy(() =>
  import('app/pages/custom-matter-detail' /* webpackChunkName: 'custom-matter-detail' */)
)
const DocketingRulesFirmSelection = lazy(() =>
  import(
    'app/pages/docketing-rules-firm-selection' /* webpackChunkName: 'docketing-rules-firm-selection' */
  )
)
const DocketingRulesFirmDetail = lazy(() =>
  import(
    'app/pages/docketing-rules-firm-detail' /* webpackChunkName: 'docketing-rules-firm-detail' */
  )
)
const SSOSetup = lazy(() => import('app/pages/sso-setup' /* webpackChunkName: 'sso-setup' */))
const WIPODesignationDetails = lazy(() =>
  import('app/pages/wipo-designations' /* webpackChunkName: 'wipo-designations' */)
)

const PAGE_INDEX = {
  autotracking: Autotracking,
  'canadian-trademark-detail': CanadianTrademarkDetail,
  'collaborator-detail': CollaboratorDetail,
  collaborators: Collaborators,
  'contact-profile': ContactProfile,
  contacts: Contacts,
  'copyright-detail': CopyrightDetail,
  'custom-matter-detail': CustomMatterDetail,
  'analytics-dashboard': AnalyticsDashboard,
  docket: Docket,
  'firm-docket': FirmDocket,
  'firm-info': FirmInfo,
  'firm-roles-manager': FirmRolesManager,
  'firm-tag-manager': FirmTagManager,
  'account-recovery': AccountRecovery,
  'licensure-detail': LicensureDetail,
  login: Login,
  'opposition-detail': OppositionDetail,
  'patent-detail': PatentDetail,
  profile: Profile,
  'pw-reset': PasswordReset,
  'report-manager': ReportManager,
  'sign-up': SignUp,
  static: Static,
  'tm-detail': TrademarkDetail,
  'intake-trademark-detail': IntakeTrademarkDetail,
  'intake-trademark-fill': IntakeTrademarkFill,
  'intake-patent-detail': IntakePatentDetail,
  'intake-patent-fill': IntakePatentFill,
  'intake-sign-up': IntakeSignUp,
  intakes: Intakes,
  'docketing-rules-firm-selection': DocketingRulesFirmSelection,
  'docketing-rules-firm-detail': DocketingRulesFirmDetail,
  'sso-setup': SSOSetup,
  'wipo-designations-details': WIPODesignationDetails
}

const appContainer = document.getElementById('content')
const pageId = appContainer.dataset.page
const Page = PAGE_INDEX[pageId]

const SuspendedPage = () => (
  <Suspense fallback={<div />}>
    <Page />
  </Suspense>
)

if (process.env.NODE_ENV === 'production') {
  const ignoreUrlPatterns = [
    /analytics\.google\.com/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i
  ]
  Sentry.init({
    dsn: PUBLIC_SENTRY_DSN,
    environment: ENV,
    release: RELEASE_VERSION,
    ignoreErrors: ['HTTPError'],
    ignoreUrls: ENABLE_SENTRY_IGNORE_URLS ? ignoreUrlPatterns : []
  })
  Sentry.withScope(() => {
    ReactDOM.render(<SuspendedPage />, appContainer)
  })
} else {
  ReactDOM.render(<SuspendedPage />, appContainer)
}
